import { template as template_af68214fce0f44efa9ba9dd90c61adb9 } from "@ember/template-compiler";
const FKLabel = template_af68214fce0f44efa9ba9dd90c61adb9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
